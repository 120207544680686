import axios from "axios"
import { useEffect, useState } from "react"
import { Legend } from "../../Components/Legend/Legend"
import { MapContainerComponent } from "../../Components/MapContainer/MapContainer"
import { Navbar } from "../../Components/Navbar/Navbar"
import { Offcanvas } from "../../Components/Offcanvas/Offcanvas"
import { Sidebar } from "../../Components/Sidebar/Sidebar"
import { hydroStations } from "../../utils/TAHMO_Stations/hydro_stations"
import { weatherStations } from "../../utils/TAHMO_Stations/weather_stations"
import "../global.css"
const rainfall = [...weatherStations, ...hydroStations]

export const RainfallForecast = () => {
    const [rainfallDatasets, setRainfallDatasets] = useState<any>({})
    const [series, setSeries] = useState<ApexAxisChartSeries>([])
    const [loading, setLoading] = useState(true)
    const [title, setTitle] = useState("")
    const [colorCheck, setColorCheck] = useState<any>({})
    const [endDate, setEndDate] = useState<Date | null>(new Date())
    const [isBar, setIsBar] = useState("area")

    useEffect(() => {
        setInterval(() => {
            window.location.reload()
        }, 1000 * 60 * 60 * 1);
    }, [])

    useEffect(() => {

        (async () => {
            const tempData: any = {}
            const colors: any = {}
            const code_name: any = {}
            const rainfallData = rainfall.map(station => {
                code_name[station.code] = station.name
                return axios(`/api/fews-app/forecasts?geocode_lat=${station.latitude}&geocode_long=${station.longitude}&station_code=${station.code}`)
            })

            const results = await Promise.all(rainfallData).then(results => results).catch(err => console.error())
            if (results) {
                const allData = results.map((res: any) => { return res.data })
                allData.forEach((rainfallData) => {
                    try {
                        const precipData = rainfallData['data']['precipitation']
                        const validTimeLocal = rainfallData['data']['time']

                        const rainfallSeries: any = {
                            rf: [{
                                name: 'Rainfall Forecast',
                                data: [],
                                type: 'bar'
                            }]
                        }

                        if (precipData.length === 0) {
                            tempData[rainfallData['code']] = null
                            colors[rainfallData['code']] = "no_data.png"
                        }

                        const dangerCheck = Math.max(...precipData)

                        for (let i = 0; i < precipData.length; i++) {
                            rainfallSeries['rf'][0]['data'].push([validTimeLocal[i].split('T')[0], precipData[i]])
                        }

                        if (precipData.length === 0) {
                            tempData[rainfallData['code']] = null
                            colors[rainfallData['code']] = "no_data.png"
                        } else {
                            tempData[rainfallData['code']] = rainfallSeries
                            if (dangerCheck >= 50) {
                                colors[rainfallData['code']] = "danger.png"
                            } else if (dangerCheck >= 25 && dangerCheck < 50) {
                                colors[rainfallData['code']] = "warning.png"
                            } else if (dangerCheck >= 10 && dangerCheck < 25) {
                                colors[rainfallData['code']] = "okay.png"
                            } else {
                                colors[rainfallData['code']] = "small.png"
                            }
                        }
                    } catch (error) {
                        tempData[rainfallData['code']] = null
                        colors[rainfallData['code']] = "no_data.png"
                    }
                })
                setRainfallDatasets(tempData)
                setLoading(false)
            }
            setColorCheck(colors)
        })()
    }, [])

    const showOffcanvas = (obj: { title: string, code: string }) => {
        try {
            setSeries(rainfallDatasets[obj.code]['rf'])
            setTitle(obj.title)
            const btn = document.getElementById('trigger') as HTMLButtonElement;
            const offcanvas = document.getElementById('offcanvasBottom');
            if (!(offcanvas?.classList.contains('show'))) {
                btn.click()
            }
        } catch (err) {
            const errDiv = document.createElement('div');
            errDiv.innerText = `No data available`;
            errDiv.id = 'flash-message';
            errDiv.className = 'flash-message';
            const content = document.getElementById("content")
            content?.appendChild(errDiv)
        }
    }

    return (
        <>
            <Navbar />
            <Sidebar endDate={endDate} setEndDate={setEndDate} setLoading={setLoading} disable={true} />
            <Legend />
            <div id="content" className="content-page">
                <div className="content" style={{ minHeight: 0 }}>
                    <div className="container-fluid">
                        <div className="row">
                            <MapContainerComponent loading={loading} stations={rainfall} offcanvasfunc={showOffcanvas} colorCheck={colorCheck} />
                            <Offcanvas title={title} series={series} isBar={isBar} setIsBar={setIsBar} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}