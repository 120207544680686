import axios from "axios";
import { useEffect, useState } from "react";
import { Legend } from "../../Components/Legend/Legend";
import { MapContainerComponent } from "../../Components/MapContainer/MapContainer";
import { Navbar } from "../../Components/Navbar/Navbar";
import { Offcanvas } from "../../Components/Offcanvas/Offcanvas";
import { Sidebar } from "../../Components/Sidebar/Sidebar";
import { hydroStations } from "../../utils/TAHMO_Stations/hydro_stations";
import "../global.css";

type valueType = string | number

export const Hydrological = () => {
    const [hydroDatasets, setHydroDatasets] = useState<any>({})
    const [series, setSeries] = useState<ApexAxisChartSeries>([])
    const [loading, setLoading] = useState(true)
    const [title, setTitle] = useState("")
    const [colorCheck, setColorCheck] = useState<any>({})
    const [endDate, setEndDate] = useState<Date | null>(new Date())
    const [isBar, setIsBar] = useState("area")

    useEffect(() => {
        setInterval(() => {
            window.location.reload()
        }, 1000 * 60 * 60 * 1);
    }, [])

    useEffect(() => {
        const btn = document.getElementById('trigger') as HTMLButtonElement;
        const offcanvas = document.getElementById('offcanvasBottom');
        if (offcanvas?.classList.contains('show')) {
            btn.click()
        }
        const startDate = new Date(endDate!.getFullYear(), endDate!.getMonth(), endDate!.getDate() - 6).toISOString().split(':')[0] + ':00:00Z';


        (async () => {
            const tempData: any = {}
            const colors: any = {}
            const allHydroStationsData = hydroStations.map(station => {
                return axios(`/api/fews-app/station_data?start=${startDate}&end=${endDate!.toISOString().split(':')[0] + ':00:00Z'}&station_code=${station.code}`)
            })

            const results = await Promise.all(allHydroStationsData).then(results => results).catch(err => {
                console.log("Server error.")
                alert("Failed to load measurements")
            })
            if (results) {
                const allData = results.map((res: any) => { return res.data })
                allData.forEach(data => {
                    try {
                        const valueInd = data['data']['results'][0]['series'][0]['columns'].indexOf('value');
                        const timeInd = data['data']['results'][0]['series'][0]['columns'].indexOf('time');
                        const varInd = data['data']['results'][0]['series'][0]['columns'].indexOf('variable');
                        const values: [] = data['data']['results'][0]['series'][0]['values']

                        const hydroSeries: any = {
                            wl: [{
                                name: 'Water Levels',
                                data: [],
                                type: 'area'
                            }]
                        }

                        const wl_vals: any = []

                        values.forEach(((value: valueType[]) => {
                            if (value[varInd] in hydroSeries) {
                                hydroSeries[value[varInd]][0]['data'].push([value[timeInd], value[valueInd]])
                                wl_vals.push(value[valueInd])
                            }
                        }))

                        const dangerCheck = wl_vals[wl_vals.length - 1]
                        if (dangerCheck >= 4) {
                            colors[data['code']] = "rainfall_danger.png"
                        } else if (dangerCheck >= 2 && dangerCheck < 4) {
                            colors[data['code']] = "rainfall_warning.png"
                        } else if (dangerCheck < 2) {
                            colors[data['code']] = "rainfall_okay.png"
                        } else {
                            colors[data['code']] = "rainfall_no_data.png"
                        }

                        tempData[data['code']] = hydroSeries
                    } catch (error) {
                        colors[data['code']] = "rainfall_no_data.png"
                    }
                })
                setHydroDatasets(tempData)
                setLoading(false)
            }
            setColorCheck(colors)
        })()
    }, [endDate])

    const showOffcanvas = (obj: { title: string, code: string }) => {
        try {
            setSeries(hydroDatasets[obj.code]['wl'])
            // setIsBar("area")
            setTitle(obj.title)
            const btn = document.getElementById('trigger') as HTMLButtonElement;
            const offcanvas = document.getElementById('offcanvasBottom');
            if (!(offcanvas?.classList.contains('show'))) {
                btn.click()
            }
        } catch (err) {
            const errDiv = document.createElement('div');
            errDiv.innerText = `No data available`;
            errDiv.id = 'flash-message';
            errDiv.className = 'flash-message';
            const content = document.getElementById("content")
            content?.appendChild(errDiv)
        }
    }

    return (
        <>
            <Navbar />
            <Sidebar endDate={endDate} setEndDate={setEndDate} setLoading={setLoading} />
            <Legend />
            <div id="content" className="content-page">
                <div className="content" style={{ minHeight: 0 }}>
                    <div className="container-fluid">
                        <div className="row">
                            <MapContainerComponent loading={loading} stations={hydroStations} offcanvasfunc={showOffcanvas} colorCheck={colorCheck} />
                            <Offcanvas title={title} series={series} setSeries={setSeries} isBar={isBar} setIsBar={setIsBar} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
