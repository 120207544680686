import axios from 'axios';
import { useEffect, useState } from 'react';
import { Legend } from '../../Components/Legend/Legend';
import { MapContainerComponent } from '../../Components/MapContainer/MapContainer';
import { Navbar } from '../../Components/Navbar/Navbar';
import { Offcanvas } from '../../Components/Offcanvas/Offcanvas';
import { Sidebar } from '../../Components/Sidebar/Sidebar';
import { weatherStations } from "../../utils/TAHMO_Stations/weather_stations";
import "../global.css";

export const Meteorological = () => {
    const [meteoDatasets, setMeteoDatasets] = useState<any>({})
    const [series, setSeries] = useState<ApexAxisChartSeries>([])
    const [seriesData, setSeriesData] = useState<any>({})
    const [loading, setLoading] = useState(true)
    const [title, setTitle] = useState("")
    const [colorCheck, setColorCheck] = useState<any>({})
    const [endDate, setEndDate] = useState<Date | null>(new Date())
    const [isBar, setIsBar] = useState("area")

    useEffect(() => {
        setInterval(() => {
            window.location.reload()
        }, 1000 * 60 * 60 * 1);
    }, [])

    useEffect(() => {
        const btn = document.getElementById('trigger') as HTMLButtonElement;
        const offcanvas = document.getElementById('offcanvasBottom');
        if (offcanvas?.classList.contains('show')) {
            btn.click()
        }
        const startDate = new Date(endDate!.getFullYear(), endDate!.getMonth(), endDate!.getDate() - 6).toISOString().split(':')[0] + ':00:00Z';

        (async () => {
            const tempData: any = {}
            const colors: any = {}
            const allWeatherStationsData = weatherStations.map(station => {
                return axios(`/api/fews-app/station_data?start=${startDate}&end=${endDate!.toISOString().split(':')[0] + ':00:00Z'}&station_code=${station.code}`)
            })

            const results = await Promise.all(allWeatherStationsData).then(results => results).catch(err => {
                console.log("Server error.")
                alert("Failed to load measurements")
            })
            if (results) {
                const allData = results.map((res: any) => { return res.data })
                allData.forEach(data => {
                    try {
                        const valueInd = data['data']['results'][0]['series'][0]['columns'].indexOf('value');
                        const timeInd = data['data']['results'][0]['series'][0]['columns'].indexOf('time');
                        const varInd = data['data']['results'][0]['series'][0]['columns'].indexOf('variable');
                        const values: [] = data['data']['results'][0]['series'][0]['values']

                        const variables: any = {
                            pr: [{ name: "Precipitation", data: [], type: "column" }],
                            te: [{ name: "Temperature", data: [], type: "area" }],
                            rh: [{ name: "Relative Humidity", data: [], type: "area" }],
                            ws: [{ name: "Wind Speed", data: [], type: "area" }],
                            ra: [{ name: "Radiation", data: [], type: "area" }]
                        }

                        const precipArray: any[][] = []

                        values.forEach((value) => {
                            if (value[varInd] in variables) {
                                if (value[varInd] === "pr") {
                                    precipArray.push(value)
                                } else {
                                    variables[value[varInd]][0]['data'].push([value[timeInd], value[valueInd]])
                                }
                            }
                        })

                        const precipObject: any = {}

                        precipArray.forEach((dt: any[]) => {
                            if (dt[timeInd].split('T')[0] in precipObject) {
                                precipObject[dt[timeInd].split('T')[0]] += dt[valueInd]
                            } else {
                                precipObject[dt[timeInd].split('T')[0]] = dt[valueInd]
                            }
                        })

                        for (const dt in precipObject) {
                            variables['pr'][0]['data'].push([dt, precipObject[dt]])
                        }

                        const vals: number[] = Object.values(precipObject)
                        const dangerCheck = vals[vals.length - 1]

                        if (variables['pr'][0]['data'].length === 0) {
                            tempData[data['code']] = null
                            colors[data['code']] = "no_data.png"
                        } else {
                            if (dangerCheck >= 50) {
                                colors[data['code']] = "danger.png"
                            } else if (dangerCheck >= 25 && dangerCheck < 50) {
                                colors[data['code']] = "warning.png"
                            } else if (dangerCheck >= 10 && dangerCheck < 25) {
                                colors[data['code']] = "okay.png"
                            } else {
                                colors[data['code']] = "small.png"
                            }

                            tempData[data['code']] = variables
                        }

                    } catch (error) {
                        tempData[data['code']] = null
                        colors[data['code']] = "no_data.png"
                    }
                })
                setMeteoDatasets(tempData)
                setLoading(false)
            }
            setColorCheck(colors)
        })()

    }, [endDate])

    const showOffcanvas = (obj: { title: string, code: string }) => {

        try {
            setSeries(meteoDatasets[obj.code]['pr'])
            setIsBar("bar")
            setSeriesData(meteoDatasets[obj.code])
            setTitle(obj.title)
            const btn = document.getElementById('trigger') as HTMLButtonElement;
            const offcanvas = document.getElementById('offcanvasBottom');
            if (!(offcanvas?.classList.contains('show'))) {
                btn.click()
            }
        } catch (err) {
            const errDiv = document.createElement('div');
            errDiv.innerText = `No data available`;
            errDiv.id = 'flash-message';
            errDiv.className = 'flash-message';
            const content = document.getElementById("content")
            content?.appendChild(errDiv)
        }
    }

    return (
        <>
            <Navbar />
            <Sidebar endDate={endDate} setEndDate={setEndDate} setLoading={setLoading} />
            <Legend />
            <div id="content" className="content-page">
                <div className="content" style={{ minHeight: 0 }}>
                    <div className="container-fluid">
                        <div className="row">
                            <MapContainerComponent loading={loading} stations={weatherStations} offcanvasfunc={showOffcanvas} colorCheck={colorCheck} />
                            <Offcanvas title={title} series={series} setSeries={setSeries} seriesData={seriesData} isBar={isBar} setIsBar={setIsBar} />
                        </div>
                    </div>
                </div>
            </div>
        </>)
}